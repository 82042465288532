/* Controls the Div with the .main Class on the App.js page */
#root > .main {
  /* display: grid; */
  margin: 0 auto !important;
  padding: 0%;
  flex: 1;
  /* border: solid 50px red !important; */
}

body,
html {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .responsive-navbar {
    display: block;
  }
}

/* Controls the body */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("assets/images/homepage/banner.jpg") repeat fixed;
  background-size: cover;
  background-color: #625448;
  background-attachment: fixed;
  position: relative;
  /* border: solid 50px red !important; */
}

/* SPINNER */

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* REVIEW CARD */

.review-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  max-width: 600px;
}

.review-card-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.review-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.review-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.review-card-description {
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
}

.review-card-rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
}


/* REVIEW LIST */
.reviews-list-container {
  max-width: 500px;
  margin: 20px auto;
  /* padding: 20px; */
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reviews-list-header {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 15px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.pagination-button {
  padding: 8px 16px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* HEADER */

header {
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
}


/* NAVIGATION BAR */

nav.navbar.navbar-expand-md.navbar-dark.bg-dark {
  background: linear-gradient(to right, #ff3d33 0%, #625448 100%);
}


/* FOOTER */
footer {
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3rem;
  background: linear-gradient(to left, #ff3d33 0%, #625448 100%);
  color: aliceblue;
}

/* FORM */
form > :nth-child(1) {
  font-size: 24px !important;
  display: grid;
  /* border: solid 10px red; */
  margin: 0 auto !important;
  align-items: center !important;
  text-align: center;
  font-weight: "bold";
}
form {
  /* border: solid 100px red !important;
   */
  max-width: 500px;
  /* color: white !important; */
  /* background-color: #625448; */
  background-color: white !important;
  margin: 2% auto !important;
  /* border: solid #8a0e0e 10px; */
  border-radius: 12px;
  padding: 2%;
  /* background-image: linear-gradient(to left, #dad1d1 0%, #ebd8d8 100%) */
}

